import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import { ThemeProvider } from "styled-components"
import theme from "../styles/theme"
import Header from "./header"
import Footer from "./footer"
import { Helmet } from "react-helmet"
import { CallBackFloatingCTA } from "./Misc/CallBackFloatingCTA"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <ThemeProvider theme={theme}>
        <Helmet>
          <script type="text/javascript">
            {`
            (function(e,t,o,n,p,r,i){e.visitorGlobalObjectAlias=n;e[e.visitorGlobalObjectAlias]=e[e.visitorGlobalObjectAlias]||function(){(e[e.visitorGlobalObjectAlias].q=e[e.visitorGlobalObjectAlias].q||[]).push(arguments)};e[e.visitorGlobalObjectAlias].l=(new Date).getTime();r=t.createElement("script");r.src=o;r.async=true;i=t.getElementsByTagName("script")[0];i.parentNode.insertBefore(r,i)})(window,document,"https://diffuser-cdn.app-us1.com/diffuser/diffuser.js","vgo");

            vgo('setAccount', '475519797');

            vgo('setTrackByDefault', true);

            vgo('process');
            `}
          </script>
          <script type="text/javascript">
            {`
            (function(w,s){var e=document.createElement("script");e.type="text/javascript";e.async=true;e.src=https://cdn-eu.pagesense.io/js/onetouchinvestment/e9f1d5e826fa4ac498fa6d9534cd7a73.js;var x=document.getElementsByTagName("script")[0];x.parentNode.insertBefore(e,x);})(window,"script");
            `}
          </script>
        </Helmet>

        <Header siteTitle={data.site.siteMetadata.title} />

        <main>{children}</main>

        <Footer />
        <CallBackFloatingCTA />
      </ThemeProvider>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
