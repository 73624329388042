/* eslint jsx-a11y/control-has-associated-label: "off", curly: "error" */

import React from "react"

const CurrentPage = () => {
  const url = typeof window !== "undefined" ? window.location.href : ""
  return (
    <div className="form-group d-none" aria-hidden="true">
      <label className="w-100" htmlFor="pageUrl">
        <span className="sr-only">Page URL</span>
        <input
          className="form-control"
          name="pageUrl"
          type="text"
          defaultValue={url}
          required
        />
      </label>
    </div>
  )
}

export default CurrentPage
