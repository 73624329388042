import React, { useState } from "react"
import { Button, Collapse, Container, Row, Col } from "react-bootstrap"
import styled from "styled-components"
import { Link } from "gatsby"
import { FaTimes, FaCaretUp } from "react-icons/fa"
import { mediaBreakpoint } from "../../utils/breakpoints"

const StyledCollapse = styled(Collapse)`
  position: absolute;
  width: 100%;
  background: ${({ theme }) => theme.primary};
  top: 100%;
  left: 0px;
  z-index: 9;

  /* The point where the menu breaks to hamburger: */
  @media ${mediaBreakpoint.down.lg} {
    position: fixed;
    top: 100px;
    height: calc(100vh - 100px);
    overflow: auto;

    .container,
    .row {
      height: 100%;
    }
  }
`

const StyledTile = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  color: #fff;
  padding: 50px;
  background-size: 100% 200%;
  background-image: linear-gradient(
    to bottom,
    transparent 50%,
    ${({ theme, variant }) => theme[variant] || "#fff"} 50%
  );
  transition: background-position 0.3s ease-in-out, color 0.3s ease-in-out;

  &:hover {
    background-position: 0 -100%;
    color: ${({ theme, variant }) => (theme[variant] ? "#fff" : theme.primary)};
    text-decoration: none;
  }

  /* The point where the menu breaks to hamburger: */
  @media ${mediaBreakpoint.down.lg} {
    padding: 15px;
  }
`

const StyledCloseButton = styled(Button)`
  position: absolute;
  top: 5px;
  right: 15px;
  border-radius: 0;
`

export const Dropdown = () => {
  const [open, setOpen] = useState(false)

  return (
    <>
      <Button
        variant="link"
        onClick={() => setOpen(!open)}
        aria-controls="investments-mega-menu"
        aria-expanded={open}
      >
        Investments
      </Button>
      <StyledCollapse in={open}>
        <div id="investments-mega-menu">
          <Container className=" py-5">
            <Row className="w-100 no-gutters">
              <Col sm={6} lg={4} className="d-flex justify-content-center">
                <StyledTile
                  variant="buy-to-let"
                  to="/buy-to-let-property-investments"
                >
                  Buy To Let
                </StyledTile>
              </Col>
              <Col sm={6} lg={4} className="d-flex justify-content-center">
                <StyledTile variant="care-home" to="/care-home-investments">
                  Care Homes
                </StyledTile>
              </Col>
              <Col sm={6} lg={4} className="d-flex justify-content-center">
                <StyledTile
                  variant="student-property"
                  to="/student-property-investments"
                >
                  Student Property
                </StyledTile>
              </Col>
              <Col sm={6} lg={4} className="d-flex justify-content-center">
                <StyledTile variant="hotel-room" to="/hotel-room-investments">
                  Hotel Room
                </StyledTile>
              </Col>
              <Col sm={6} lg={4} className="d-flex justify-content-center">
                <StyledTile
                  variant="secondary"
                  to="/Manchester-Property-Investments"
                >
                  Manchester Property
                </StyledTile>
              </Col>
              <Col sm={6} lg={4} className="d-flex justify-content-center">
                <StyledTile to="/search-result">
                  Search all Investments
                </StyledTile>
              </Col>
            </Row>
          </Container>

          <StyledCloseButton variant="secondary" onClick={() => setOpen(!open)}>
            <FaTimes className="d-none d-lg-inline" />
            <FaCaretUp className="d-lg-none" />
          </StyledCloseButton>
        </div>
      </StyledCollapse>
    </>
  )
}
