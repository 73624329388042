/* eslint jsx-a11y/control-has-associated-label: "off", curly: "error" */

import React from "react"

const mailingLists = [
  "Buy-to-Let UK Properties",
  "Student Property Investments",
  "Care Home Investments",
  "Hotel Room Investments",
  "Property Market News",
  "All Investment Property Alerts",
  "Joint Venture on Property Developments",
]

const MailingLists = () => (
  <div className="form-group">
    <fieldset>
      <p className="p">Mailing Lists:</p>
      {mailingLists.map((val, i) => (
        <div key={i} className="form-check">
          <label className="form-check-label">
            <input
              className="form-check-input"
              type="checkbox"
              value={val}
              name="mailingLists[]"
            />
            {val}
          </label>
        </div>
      ))}
    </fieldset>
  </div>
)

export default MailingLists
