import { Link } from "gatsby"
import PropTypes from "prop-types"
import React, { useState } from "react"
import { Navbar } from "react-bootstrap"
import { Navigation } from "./Navigation/Nav"
import Logo from "../images/Logo-Text.svg"
import styled from "styled-components"
import { FaTimes, FaBars } from "react-icons/fa"

const StyledNavbar = styled(Navbar)`
  padding: 0;
  align-items: stretch;
  margin-bottom: 50px;

  .navbar-brand {
    flex: 1;

    .logo {
      padding: 15px;
    }
  }

  .navbar-toggler {
    background-color: ${({ theme }) => theme.secondary};
    color: #fff;
    border-radius: 0;
    padding: 1.5rem;
  }

  .navbar-collapse {
    align-items: stretch;
  }
`

const Header = ({ siteTitle }) => {
  const [expanded, setExpanded] = useState(false)
  return (
    <header className="sticky-top">
      <StyledNavbar bg="primary" variant="dark" expand="lg" expanded={expanded}>
        <Navbar.Brand as={Link} to="/">
          <img
            className="logo"
            src={Logo}
            alt={siteTitle}
            width="230"
            height="90"
          />
        </Navbar.Brand>
        <Navbar.Toggle
          onClick={() => setExpanded(expanded ? false : "expanded")}
        >
          {expanded ? <FaTimes /> : <FaBars />}
        </Navbar.Toggle>
        <Navbar.Collapse>
          <Navigation setExpanded={setExpanded}/>
        </Navbar.Collapse>
      </StyledNavbar>
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
