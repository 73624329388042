import React from "react"
import styled from "styled-components"

const StyledSVG = styled.svg`
  display: block;
  position: relative;
  width: 100%;
  background-color: ${props => props.theme[props.background]};
  /* height: 50px; */
  /* No fixed height, or different fixed heights for media queries? */

  path {
    fill: ${props => props.theme[props.variant]};
  }
`

export const CurveBulgeTop = ({ variant = "primary", background }) => {
  return (
    <StyledSVG
      className="curve bulge top"
      variant={variant}
      background={background}
      preserveAspectRatio="none"
      viewBox="0 0 1920.1 49.5"
    >
      <path
        fill="#2597C6"
        d="M1920.1,49.5v-2.9C983.5-58.2,0,46.6,0,46.6v2.9H1920.1z"
      />
    </StyledSVG>
  )
}

export const CurveBulgeBottom = ({ variant = "primary", background }) => {
  return (
    <StyledSVG
      className="curve bulge bottom"
      variant={variant}
      background={background}
      preserveAspectRatio="none"
      viewBox="0 0 1920.1 49.5"
    >
      <path fill="#2597C6" d="M0,0v2.9c0,0,983.5,104.8,1920.1,0V0H0z" />
    </StyledSVG>
  )
}

export const CurveCarveTop = ({ variant = "primary", background }) => {
  return (
    <StyledSVG
      className="curve carve top"
      variant={variant}
      background={background}
      viewBox="0 0 1920.1 49.3"
      preserveAspectRatio="none"
    >
      <path fill="#003045" d="M1920.1,0C983.5,104.8,0,0,0,0v49.3h1920.1V0z" />
    </StyledSVG>
  )
}
export const CurveCarveBottom = ({ variant = "primary", background }) => {
  return (
    <StyledSVG
      className="curve carve bottom"
      variant={variant}
      background={background}
      viewBox="0 0 1920.1 49.3"
      preserveAspectRatio="none"
    >
      <path fill="#003045" d="M1920.1,0H0v49.3c0,0,983.5-104.8,1920.1,0V0z" />
    </StyledSVG>
  )
}
