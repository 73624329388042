import React from "react"
import styled from "styled-components"
import { CallMeBack } from "./CallMeBack"
import { mediaBreakpoint } from "../../utils/breakpoints"

const StyledFloatingCTA = styled.div`
  position: fixed;
  bottom: 15px;
  right: 15px;
  background-color: ${({ theme }) => theme.secondary};
  color: #fff;
  padding: 15px 30px;
  border-radius: 30px;
  z-index: 1;

  display: none;

  @media ${mediaBreakpoint.down.lg} {
    display: block;
  }
`

export const CallBackFloatingCTA = () => {
  return (
    <StyledFloatingCTA>
      <CallMeBack />
    </StyledFloatingCTA>
  )
}
