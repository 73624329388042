/* eslint jsx-a11y/control-has-associated-label: "off", curly: "error" */

import React, { Fragment } from "react"

import { Row, Col } from "react-bootstrap"

const CommonFields = () => (
  <Fragment>
    <p className="sr-only">
      <label>
        Don’t fill this out if you’re human: <input name="bot-field" />
      </label>
    </p>

    <Row>
      <Col md={6}>
        <div className="form-group">
          <label className="w-100" htmlFor="firstName">
            <span className="sr-only">First name</span>
            <input
              className="form-control"
              name="firstName"
              placeholder="First name"
              type="text"
              required
            />
          </label>
        </div>
      </Col>
      <Col md={6}>
        <div className="form-group">
          <label className="w-100" htmlFor="lastName">
            <span className="sr-only">Last name</span>
            <input
              className="form-control"
              name="lastName"
              placeholder="Last name"
              type="text"
              required
            />
          </label>
        </div>
      </Col>
    </Row>

    <div className="form-group">
      <label className="w-100" htmlFor="email">
        <span className="sr-only">Email</span>
        <input
          className="form-control"
          name="email"
          placeholder="Your email address"
          type="email"
          required
        />
      </label>
    </div>

    <div className="form-group">
      <label className="w-100" htmlFor="phone">
        <span className="sr-only">Phone number</span>
        <input
          className="form-control"
          name="phone"
          placeholder="Your phone number"
          type="text"
          required
        />
      </label>
    </div>
  </Fragment>
)

export default CommonFields
