//? These variables are used within Styled Components
//? Make sure to change anything in the sister file; "theme.scss"

//! This would be cool, but doesn't work with Styled Components ThemeProvider:
//! https://til.hashrocket.com/posts/sxbrscjuqu-share-scss-variables-with-javascript

export default {
  primary: "#002f45",
  secondary: "#13b906",
  light: "#EBEBEB",
  white: "#ffffff",
  "buy-to-let": "#7c5b4c",
  "care-home": "#98258f",
  "student-property": "#DFB500",
  "hotel-room": "#B9066D",
}
