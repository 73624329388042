import React, { useState } from "react"
import { Modal } from "react-bootstrap"

export const ModalPopUp = ({ children, trigger }) => {
  const [show, setShow] = useState(false)

  const handleClose = () => setShow(false)

  const handleShow = e => {
    e.preventDefault()
    setShow(true)
  }

  return (
    <>
      <span
        onClick={e => handleShow(e)}
        onKeyPress={e => handleShow(e)}
        role="button"
        tabIndex="0"
      >
        {trigger}
      </span>

      <Modal centered show={show} onHide={handleClose}>
        <Modal.Header
          className="border-0 pb-0 d-lg-none"
          closeButton
        ></Modal.Header>
        <Modal.Body>{children}</Modal.Body>
      </Modal>
      <span className="d-none" aria-hidden="true">
        {children}
      </span>
    </>
  )
}
