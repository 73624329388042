import { useStaticQuery, graphql } from "gatsby"

export const useHubPages = () => {
  const { area, sector } = useStaticQuery(
    graphql`
      query HubPagesQuery {
        area: allContentfulHubArea {
          nodes {
            id
            title
            subtitle
            permalink
            key
          }
        }
        sector: allContentfulHubSector {
          nodes {
            id
            title
            subtitle
            permalink
            key
          }
        }
      }
    `
  )

  return {
    areas: area.nodes,
    sectors: sector.nodes,
  }
}
