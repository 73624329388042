import React from "react"

export const SqueezePageCTX = React.createContext()
export const SqueezePageContext = ({ location, menuItems, children }) => {
  return (
    <SqueezePageCTX.Provider
      value={{
        isSqueezePage: true,
        location,
        menuItems,
      }}
    >
      {children}
    </SqueezePageCTX.Provider>
  )
}
