import React, { useContext } from "react"
import { Nav } from "react-bootstrap"
import { Dropdown } from "./Dropdown"
import { Link } from "gatsby"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import styled from "styled-components"
import { SqueezePageCTX } from "../../templates/squeeze-pages/SqueezePageContext"
import slugify from "@sindresorhus/slugify"
import { ModalPopUp } from "../Modal/ModalPopUp"
import CallBackForm from "../Forms/CallBackForm"
import GetPropertyDetailsForm from "../Forms/GetPropertyDetailsForm"

const StyledNav = styled(Nav)`
  .nav-link,
  .btn-link {
    display: flex;
    align-items: center;
    padding: 1.5rem !important;
    border-radius: 0;
    color: #fff !important;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

    &:hover,
    &:focus {
      background: #fff;
      color: ${({ theme }) => theme.primary} !important;
      text-decoration: none;
      box-shadow: none;
    }

    &:last-child {
      background-color: ${({ theme }) => theme.secondary};
      color: #fff !important;

      &:hover {
        background: #fff;
        color: ${({ theme }) => theme.primary} !important;
        text-decoration: none;
      }
    }
  }
`

export const Navigation = ({setExpanded}) => {
  const squeezePageCtx = useContext(SqueezePageCTX)

  if (squeezePageCtx && squeezePageCtx.isSqueezePage) {
    const { location, menuItems } = squeezePageCtx

    if (menuItems) {
      return (
        <StyledNav onClick={() => setExpanded(false)} className="ml-auto">
          {menuItems.map(menuItem => {

            if(menuItem.toLowerCase() === "contact"){
              return(
                  <ModalPopUp trigger={<span className="nav-link h-100">{menuItem}</span>}>             
                    <CallBackForm showText/>
                  </ModalPopUp>            
              )
            }else{
              const toURL = `${location.pathname}#${slugify(
                menuItem
              ).toLowerCase()}`    
              return (
                <Nav.Link as={AnchorLink} to={toURL}>
                  {menuItem}
                </Nav.Link>
              )
            }

          })}
        </StyledNav>
      )
    }
    return (
      <StyledNav onClick={() => setExpanded(false)} className="ml-auto">
        <Nav.Link as={AnchorLink} to={`${location.pathname}#top`}>
          Features
        </Nav.Link>
        <Nav.Link
          as={AnchorLink}
          to={`${location.pathname}#summary-specification`}
        >
          Summary &amp; Specification
        </Nav.Link>
        <Nav.Link as={AnchorLink} to={`${location.pathname}#photos`}>
          Images
        </Nav.Link>
        <Nav.Link as={AnchorLink} to={`${location.pathname}#numbers`}>
          Details
        </Nav.Link>
        <ModalPopUp trigger={<span className="nav-link h-100">Contact</span>}>             
          <GetPropertyDetailsForm showText/>
        </ModalPopUp>   
      </StyledNav>
    )
  }

  return (
    <StyledNav className="ml-auto">
      {/* <Nav.Link as={Link} to="/">
        Home
      </Nav.Link> */}
      <Nav.Link as={Link} to="/property-investment-company">
        About
      </Nav.Link>
      <Nav.Link as={Link} to="/uk-property-investment-guides-videos">
        Guidance
      </Nav.Link>
      <Nav.Link as={Link} to="/property-news-uk">
        News
      </Nav.Link>

      <Dropdown />

      <Nav.Link as={Link} to="/contact">
        Contact
      </Nav.Link>
    </StyledNav>
  )
}
