/* eslint jsx-a11y/control-has-associated-label: "off", curly: "error" */

import React from "react"

import { Button } from "react-bootstrap"

const NetlifyForm = ({ name, successPage, btnText, children }) => (
  <form
    className="my-4"
    name={name}
    method="POST"
    netlify-honeypot="bot-field"
    action={successPage ? `/${successPage}` : "/form-success"}
    data-netlify="true"
  >
    <div className="sr-only">
      <label>
        Don’t fill this out if you’re human: <input name="bot-field" />
      </label>
    </div>

    {/* Hiiden form field for netlify */}
    <input type="hidden" name="form-name" value={name} />

    {children}

    <Button className="btn btn-secondary btn-block" type="submit">
      {btnText}
    </Button>
  </form>
)

export default NetlifyForm
