import React, { Fragment } from "react"
import { Button } from "react-bootstrap"
import { ModalPopUp } from "../Modal/ModalPopUp"
import CallBackForm from "../Forms/CallBackForm"
import { FaPhone } from "react-icons/fa"

export const CallMeBack = ({ btnLg }) => {
  return (
    <Fragment>
      <ModalPopUp
        trigger={
          <Button variant="secondary" className={`${btnLg ? "btn-lg" : ""}`}>
            <FaPhone className="mr-2 mb-1" />
            Call Me Back
          </Button>
        }
      >
        <CallBackForm showText />
      </ModalPopUp>
    </Fragment>
  )
}
